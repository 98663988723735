<script setup lang="ts">
const games = gamesUtils.list
const orderedKeys = gamesUtils.gameOrder.filter((gameKey) => {
  return games[gameKey].active
})
</script>

<template>
  <template v-if="orderedKeys?.length">
    <div class="flex flex-wrap max-w-5xl mx-auto justify-center p-2">
      <div
        v-for="gameKey in orderedKeys"
        :key="gameKey"
        class="p-2 w-full max-w-[170px] big:max-w-[220px]"
      >
        <router-link
          :to="games[gameKey].route.path"
          class="gs-logo p-4 w-full max-w-[155px] big:max-w-[200px] shadow-standard hover:shadow-xl h-28 rounded-xl flex justify-center items-center bg-black box-hover-effect"
        >
          <el-image
            :src="games[gameKey].logo"
            :alt="`logo ${games[gameKey].name}`"
          />
        </router-link>
      </div>
    </div>
  </template>
</template>

<style scoped lang="postcss"></style>
