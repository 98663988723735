<script setup lang="ts">
import { gamesUtils } from '@/compositions/gamesUtils'

interface Props {
  topOffers: { [key in Games.GameKeyType]?: Array<Games.Offer> }
}

const props = defineProps<Props>()
const { list: games, gameLoggedOrder } = gamesUtils
const gamesKeysWithOffers = computed(() => {
  return gameLoggedOrder.value.filter((gameKey) => {
    return !!props.topOffers?.[gameKey]?.length
  })
})
</script>

<template>
  <div class="max-w-lg big:max-w-4xl m-auto p-2">
    <div
      v-for="gameKey in gamesKeysWithOffers"
      :key="gameKey"
      class="flex flex-wrap justify-between items-center mt-8"
    >
      <div class="flex-auto pl-2 uppercase font-extrabold text-2xl">
        {{ games[gameKey].name }}
      </div>
      <div class="flex-1 text-right pr-2 big:pr-6">
        <router-link :to="games[gameKey].route.path">
          <el-button
            class="text-white rounded-lg bg-[#2f7c8b] bg-gradient-to-r from-[#2f7c8b] to-[#0c687e] hover:opacity-90"
            size="large"
            style="--el-button-size: 48px"
          >
            <div class="text-base tracking-wide flex items-center">
              View all
              <icon-ify
                icon="iconamoon:arrow-right-2-bold"
                class="inline text-xl"
              />
            </div>
          </el-button>
        </router-link>
      </div>
      <div class="w-full text-center mt-5">
        <div class="grid grid-cols-2 big:grid-cols-4 gap-2 big:gap-4">
          <offer-box
            v-for="(offer, index) in topOffers[gameKey]?.slice(0, 4)"
            :key="`${gameKey}_${index}`"
            :offer="offer"
            mini
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>
